import React from "react"
import styles from "./mainSliderControl.module.css"
import { Fragment } from "react"

const NUM_SLIDES = 6;
const slides = Array.apply(null, {length: NUM_SLIDES+1}).map(Number.call, Number)
const SPACING = 90;

const MainSliderControls = (props) => {
  return (
    <div className={styles.mainSlider}>

      <div style={{width: `${SPACING*(NUM_SLIDES +1)}PX`}}  className={styles.controlsContainer}>
        <div style={{width: `${SPACING*NUM_SLIDES}PX`}} className={styles.sliderLine}>

        </div>
        { slides.map((elm, i) => {
          return (
            <Fragment>
              {/*<span style={{position: 'absolute', left: `${SPACING*i}px`, top: '-75%'}}  className={styles.labelNumber}>{i}</span>*/}
              <a style={{position: 'absolute', left: `${SPACING*i}px`, top: '35%'}} className={styles.sliderCircle}></a>
            </Fragment>
          );
        })}
      </div>
    </div>
  )
}

MainSliderControls.propTypes = {}

MainSliderControls.defaultProps = {}

export default MainSliderControls

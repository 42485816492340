import React from "react"
import styles from "./featureElement.module.css"
import Responsive from 'react-responsive';

import { Fragment } from "react";

const Desktop = props => <Responsive {...props} minWidth={1130} />;
const Tablet = props => <Responsive {...props} minWidth={979} maxWidth={1130} />;
const Mobile = props => <Responsive {...props} maxWidth={978} />;
// const Default = props => <Responsive {...props} minWidth={768} />;


const FeatureElement = (props) => {
  const { title, featuresList, icon, ordinal } = props;

  return (
    <Fragment>
      <Mobile>
        <div onClick={()=>{window.location.href=`/product/?ordinal=${ordinal}`}} className={styles.featureElement}>
          <div className={styles.elementTitleMobile}>
            <div className={styles.leftContent}>
              <img className={styles.icon} src={icon.fluid.src}  alt=""/>
            </div>
            <div className={styles.rightContentMobile}>
              <h4>
                <strong> {title}</strong>
              </h4>
            </div>
          </div>
          <div className={styles.elementDescription}>
            { featuresList.map(feature => {

              return (
                <p className={styles.featureText}>{feature}</p>
              )
            })}
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div  onClick={()=>{window.location.href=`/product/?ordinal=${ordinal}`}} className={styles.featureElementTablet}>
          <div className={styles.elementTitleDesktop}>
            <div className={styles.leftContent}>
              {/*<img className={styles.icon} src={icon.fluid.src}  alt=""/>*/}
              <img className={styles.icon} src={icon.fluid.src}  alt=""/>
            </div>
            <div className={styles.rightContent}>
              <h4>
                <strong> {title}</strong>
              </h4>
            </div>
          </div>
          <div className={styles.elementDescription}>
            { featuresList.map(feature => {

              return (
                <p className={styles.featureText}>{feature}</p>
              )
            })}
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div  onClick={()=>{window.location.href=`/product/?ordinal=${ordinal}`}} className={styles.featureElementDesktop}>
          <div className={styles.elementTitleDesktop}>
            <div className={styles.leftContent}>
              {/*<img className={styles.icon} src={icon.fluid.src}  alt=""/>*/}
              <img className={styles.icon} src={icon.fluid.src}  alt=""/>
            </div>
            <div className={styles.rightContent}>
              <h4>
                <strong> {title}</strong>
              </h4>
            </div>
          </div>
          <div className={styles.elementDescription}>
            { featuresList.map(feature => {

              return (
                <p className={styles.featureText}>{feature}</p>
              )
            })}
          </div>
        </div>
      </Desktop>
    </Fragment>
  )
}

FeatureElement.propTypes = {
}

FeatureElement.defaultProps = {

}
export default FeatureElement

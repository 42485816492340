import { StaticQuery } from "gatsby"
import React from "react"

import ModalVideo from "react-modal-video"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import MainSliderControls from "../MainSliderControls/MainSliderControls"
import { FaPlay } from "react-icons/fa"
import { MdArrowDownward } from "react-icons/md"
import Button from "react-bootstrap/Button"
import { Fragment } from "react"
import styles from "./videoBlock.module.css"


const VideoBlock = (props) => {

  return (
    <StaticQuery
      query={graphql`query VideoBlock {
  mainImage: imageSharp(fluid: {originalName: {eq: "mainImage.png"}}) {
    fluid {
      aspectRatio
      originalImg
      originalName
      presentationHeight
      presentationWidth
      sizes
      src
      srcSet
    }
  }
  mainImageFull: imageSharp(fluid: {originalName: {eq: "mainImageFull.png"}}) {
    fluid {
      aspectRatio
      originalImg
      originalName
      presentationHeight
      presentationWidth
      sizes
      src
      srcSet
    }
  }
}`}
      render={data => {

        return (
          <VideoContent data={data} {...props}/>
        )
      }
      }
    />
  )
}

class VideoContent extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    const { data } = this.props
    return (
      <Fragment>

        <div className={`${styles.videoBlockContainerDesktop}`}>
          <div className={`${styles.contentLeft}`}>
            <div className={styles.imageLeft}>
              <Img style={{ width: "700px", height: "680px", marginLeft: "auto" }} fluid={data.mainImageFull.fluid}/>
            </div>
            <div onClick={() => {
              if (typeof document !== "undefined") {
                document.getElementById("featuresD").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
              }
            }} className={styles.moreInfoButton}>
              <div className={styles.moreInfoTextContainer}>
                Get to <br/>
                know more
              </div>
              <div className={styles.moreInfoIcon}>
                <MdArrowDownward size={36}/>
              </div>

            </div>
          </div>
          <div className={`${styles.contentRight}`}>
            <div className={styles.titleTextContainer}>
              <h1 className={styles.titleRight}>Transforming Boards through technology. Simple. Accessible. Innovative</h1>
              <p className={styles.descriptionRight}>
                Boards of Directors are an incredibly valuable asset. <strong>Exeboard</strong> provides tools that increase Board commitment,
                engagement and communication, thus multiplying their positive strategic impact.
              </p>
            </div>
            <div className={`${styles.playVideoContainerDesktop}`}>
              <Button onClick={this.openModal} className={styles.playButtonDesktop}> <FaPlay
                className={styles.iconPlay} size={14}/> <span
                className={styles.buttonText}>Watch Video</span></Button>
              <MainSliderControls/>
            </div>

          </div>
        </div>

        <div className={`${styles.videoBlockContainerTablet}`}>
          <div className={`${styles.contentLeftTablet}`}>
            <div className={styles.imageLeft}>
              <Img style={{ width: "700px", height: "680px", marginLeft: "auto" }} fluid={data.mainImageFull.fluid}/>
            </div>
            <div onClick={() => {
              if (typeof document !== "undefined") {
                document.getElementById("featuresD").scrollIntoView({ behavior: "smooth", block: "center" })
              }
            }} className={styles.moreInfoButton}>
              <div className={styles.moreInfoTextContainer}>
                Get to <br/>
                know more
              </div>
              <div className={styles.moreInfoIcon}>
                <MdArrowDownward size={36}/>
              </div>

            </div>
          </div>
          <div className={`${styles.contentRightTablet}`}>
            <div className={styles.titleTextContainerTablet}>
              <h1 className={styles.titleRightTablet}>Transforming Boards through technology. Simple. Accessible. Innovative</h1>
              <p className={styles.descriptionRightTablet}>
                Boards of Directors are an incredibly valuable asset. <strong>Exeboard</strong> provides tools that increase Board commitment,
                engagement and communication, thus multiplying their positive strategic impact.
              </p>
            </div>
            <div className={`${styles.playVideoContainerTablet}`}>
              <Button onClick={this.openModal} className={styles.playButtonDesktop}> <FaPlay
                className={styles.iconPlay} size={20}/> <span
                className={styles.buttonText}>Watch Video</span></Button>
            </div>

          </div>
        </div>

        <div className={`${styles.videoBlockContainer}`}>
          <div className={`${styles.imageContainer}`}>
              <Img style={{ height: "160px" }} fluid={data.mainImage.fluid}/>
          </div>
          <div className={`${styles.titleContainer}`}>
            <h1 className={styles.title}>Transforming Boards through technology. Simple. Accessible. Innovative</h1>
          </div>
          <div className={`${styles.descriptionContainer}`}>
            <p className={styles.description}>
              Boards of Directors are an incredibly valuable asset. <strong>Exeboard</strong> provides tools that increase Board commitment,
              engagement and communication, thus multiplying their positive strategic impact.
            </p>
          </div>
          <div className={`${styles.playVideoContainer}`}>
            <Button onClick={this.openModal} className={styles.playButton}> <FaPlay className={styles.playIcon}
                                                                                    size={14}/> Watch Video</Button>
          </div>
        </div>

        <ModalVideo channel='vimeo' isOpen={this.state.isOpen} autopause={false} autoplay={true} videoId='256458172'
                    onClose={() => this.setState({ isOpen: false })}/>
      </Fragment>

    )
  }
}

VideoBlock.propTypes = {}

VideoBlock.defaultProps = {}
export default VideoBlock

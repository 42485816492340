import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./featuresBlock.module.css"
import { graphql } from "gatsby"
import FeatureElement from "../FetaureElement/FeatureElement";
import { Fragment } from "react";

const FeaturesBlock = (props) => {

  return (
    <StaticQuery
      query={graphql`query Features {
  allContentfulFeatures {
    edges {
      node {
        id
        ordinal
        title
        featuresList
        iconName
        icon {
          contentful_id
          fluid {
            aspectRatio
            base64
            src
            srcSet
          }
          description
        }
      }
    }
  }
}
`}
      render={data => {
        return (
          <Fragment>

              <div className={`${styles.featuresContainerMobile}`}>
                <div className={`${styles.featuresTitleContainerMobile}`}>
                  <h3 className={styles.title}>Invest in your company’s most valuable decision makers. </h3>
                </div>
                <div id='featuresM' className={styles.featuresContainer}>
                  {
                    data.allContentfulFeatures.edges.map(feature =>{

                      return(
                        <FeatureElement
                          ordinal={feature.node.ordinal}
                          key={feature.node.title}
                          title={feature.node.title}
                          featuresList={feature.node.featuresList}
                          iconName={feature.node.iconName}
                          icon={feature.node.icon}
                        />
                      )
                    })
                  }
                </div>
              </div>

              <div  className={`${styles.featuresContainerDesktop}`}>
                <div className={`${styles.featuresTitleContainer}`}>
                  <h3 className={styles.title}>Invest in your company’s most valuable decision makers. </h3>
                </div>
                <div id='featuresD' className={styles.featuresDesktop}>
                  {
                    data.allContentfulFeatures.edges.map(feature =>{

                      return(
                        <FeatureElement
                          ordinal={feature.node.ordinal}
                          key={feature.node.title}
                          title={feature.node.title}
                          featuresList={feature.node.featuresList}
                          iconName={feature.node.iconName}
                          icon={feature.node.icon}
                        />
                      )
                    })
                  }
                </div>
              </div>
          </Fragment>


        )
      }
      }
    />
  )
}

FeaturesBlock.propTypes = {
}

FeaturesBlock.defaultProps = {

}
export default FeaturesBlock

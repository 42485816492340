import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./getOnBoardBlock.module.css"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Fragment } from "react"



const GetOnBoardBlock = (props) => {
  return (
    <StaticQuery
      query={graphql`query GetOnBoard {
  allContentfulGetOnBoardBlock {
    edges {
      node {
        id
        hashtagTitle
        parragraphSmall {
          json
        }
        parragraphBig {
          json
        }
        picture {
          fluid(quality: 100, maxHeight:700) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
}

`}
      render={data => {
        return (
          data.allContentfulGetOnBoardBlock.edges.map(elm => {
            return (
              <Fragment>

                  <div className={styles.getOnBoard}>
                    <div className={styles.containerPicture}>
                      <Img style={{maxHeight: '220px'}} fluid={elm.node.picture.fluid} />
                    </div>
                    <div className={styles.containerDescription}>
                      <div className={styles.hashTagContainer}>
                        <h1 className={styles.hashTagText}>#{ elm.node.hashtagTitle}</h1>
                      </div>
                      <div className={styles.parragraphSmall}>
                        { elm.node.parragraphSmall.json.content[0].content[0].value}
                      </div>
                      <div className={styles.parragraphBig}>
                        <strong>Exeboard </strong>{ elm.node.parragraphBig.json.content[0].content[0].value}
                      </div>
                    </div>
                  </div>


                  <div className={styles.getOnBoardDesktop}>
                    <div style={{maxHeight:700}} className={styles.containerPictureDesktop}>
                      <Img style={{maxHeight:700}} fluid={elm.node.picture.fluid} />
                    </div>
                    <div className={styles.containerDescriptionDesktop}>
                      <div className={styles.hashTagContainer}>
                        <h1 className={styles.hashTagTextDesktop}>#{ elm.node.hashtagTitle}</h1>
                      </div>
                      <div className={styles.parragraphSmallDesktop}>
                        { elm.node.parragraphSmall.json.content[0].content[0].value}
                      </div>
                      <div className={styles.parragraphBigDesktop}>
                        <strong>Exeboard</strong> { elm.node.parragraphBig.json.content[0].content[0].value}
                      </div>
                    </div>
                  </div>

                  <div className={styles.getOnBoardTablet}>
                    <div className={styles.containerPictureDesktop}>
                      <Img fluid={elm.node.picture.fluid} />
                    </div>
                    <div className={styles.containerDescriptionTablet}>
                      <div className={styles.hashTagContainer}>
                        <h1 className={styles.hashTagTextDesktop}>#{ elm.node.hashtagTitle}</h1>
                      </div>
                      <div className={styles.parragraphSmallDesktop}>
                        { elm.node.parragraphSmall.json.content[0].content[0].value}
                      </div>
                      <div className={styles.parragraphBigDesktop}>
                        <strong>Exeboard</strong> { elm.node.parragraphBig.json.content[0].content[0].value}
                      </div>
                    </div>
                  </div>
              </Fragment>
            )
          })

        )
      }
    }
    />

  )
}

GetOnBoardBlock.propTypes = {}

GetOnBoardBlock.defaultProps = {}
export default GetOnBoardBlock

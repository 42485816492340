import React from "react"

import Layout from "../components/layout"
import FeaturesBlock from "../components/FeaturesBlock/FeaturesBlock.js"
import VideoBlock from "../components/VideoBlock/VideoBlock.js"
import FindoutMoreBlock from "../components/FindoutMoreBlock/FindoutMoreBlock.js"
import GetOnBoardBlock from "../components/GetOnBoardBlock/GetOnBoardBlock.js"
import ContactBlock from "../components/ContactBlock/ContactBlock.js"

const IndexPage = () => (
  <Layout>
    <VideoBlock/>
    <FeaturesBlock />
    <FindoutMoreBlock />
    <GetOnBoardBlock />
    <ContactBlock />
  </Layout>
)

export default IndexPage

import { StaticQuery } from "gatsby"
import React from "react"
import styles from "./findoutMoreBlock.module.css"
import { graphql } from "gatsby"
import Slide from 'react-reveal/Slide';
import Img from "gatsby-image"


const FindoutMoreBlock = (props) => {
  return (
    <StaticQuery
      query={graphql`query Findout {
  allContentfulFindOutMoreImages {
    edges {
      node {
        id
        name
        ordinal
        image {
          fluid(quality: 100) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
  imagesTablet: allContentfulFindOutMoreImages {
    edges {
      node {
        id
        name
        ordinal
        image {
          fluid(quality: 100, maxWidth: 800, resizingBehavior: SCALE) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
  imagesMobile: allContentfulFindOutMoreImages {
    edges {
      node {
        id
        name
        ordinal
        image {
          fluid(quality: 100, maxWidth: 350, resizingBehavior: SCALE) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
}
`}
      render={data => {
        return (
          <div className={styles.findOutMoreBlock}>
            <div className={styles.container}>
              <div className={styles.bigScreen}>
                <Slide bottom>
                <div className={styles.imagesContainer}>
                  {
                    data.allContentfulFindOutMoreImages.edges.map(curr =>{
                      const elm = curr.node;

                      if (elm.ordinal === 1){
                        return (
                          <Img className={styles.firstImage} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 2){
                        return (
                          <Img className={styles.secondImage} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 3){
                        return (
                          <Img className={styles.thirdImage} fluid={elm.image.fluid}/>
                        )
                      }

                      return '';
                    })
                  }
                </div>
                </Slide>
              </div>
              <div className={styles.tablet}>
                <div className={styles.imagesContainerTablet}>
                  {
                    data.imagesTablet.edges.map(curr =>{
                      const elm = curr.node;

                      if (elm.ordinal === 1){
                        return (
                          <Img className={styles.firstImageTable} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 2){
                        return (
                          <Img className={styles.secondImageTablet} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 3){
                        return (
                          <Img className={styles.thirdImageTablet} fluid={elm.image.fluid}/>
                        )
                      }

                      return '';
                    })
                  }
                </div>
              </div>
              <div className={styles.mobileMedium}>
                <div className={styles.imagesContainerMobileMedium}>
                  {
                    data.imagesMobile.edges.map(curr =>{
                      const elm = curr.node;

                      if (elm.ordinal === 1){
                        return (
                          <Img className={styles.firstImageMobileMedium} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 2){
                        return (
                          <Img className={styles.secondImageMobileMedium} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 3){
                        return (
                          <Img className={styles.thirdImageMobileMedium} fluid={elm.image.fluid}/>
                        )
                      }

                      return '';
                    })
                  }
                </div>
              </div>
              <div className={styles.mobileSmall}>
                <div className={styles.imagesContainerMobile}>
                  {
                    data.imagesMobile.edges.map(curr =>{
                      const elm = curr.node;
                      if (elm.ordinal === 1){
                        return (
                          <Img className={styles.firstImageMobile} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 2){
                        return (
                          <Img className={styles.secondImageMobile} fluid={elm.image.fluid}/>
                        )
                      }
                      if (elm.ordinal === 3){
                        return (
                          <Img className={styles.thirdImageMobile} fluid={elm.image.fluid}/>
                        )
                      }

                      return '';
                    })
                  }
                </div>
              </div>
              <div className={styles.callToAction}>
                <h3>Find out more about our features</h3>
                <a href="/product/"className={styles.productLink} >Go to product</a>
              </div>
            </div>


          </div>


        )
      }
      }
    />

  )
}

FindoutMoreBlock.propTypes = {
}

FindoutMoreBlock.defaultProps = {

}
export default FindoutMoreBlock
